@use 'styles/config' as *;
@use "sass:math";

.hero {
  position: relative;
  margin-top: -$layout-padding-top-mobile;
  margin-bottom: 3rem;
  padding-top: $layout-padding-top-mobile;
  padding-bottom: $layout-padding-bottom-mobile;
  height: 100vh;
  min-height: 80rem;

  color: #fff;

  background: #111;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 20rem;
    z-index: 1;

    background: linear-gradient(0deg, rgba(#000, 0), rgba(#000, 0.5));
  }

  @include nav-offset-padding();

  @media (min-width: $min-720) {
    margin-top: -$layout-padding-top;
    margin-bottom: 5rem;
    padding-top: $layout-padding-top;
    padding-bottom: $layout-padding-bottom;
  }

  &__container {
    @include container();

    display: flex;
    align-items: flex-end;

    position: relative;
    z-index: 1;

    height: 100%;

    @media (min-width: $min-720) {
      align-items: center;
    }
  }

  &__row {
    @include grid-row;
  }

  &__content {
    @include grid-col();
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;

    @media (min-width: $min-720) {
      @include grid-col(7);
    }

    @media (min-width: $min-960) {
      @include grid-col(7);
    }
  }

  &__tagline {
    display: flex;
    align-items: center;

    margin: 0 0 4rem;

    &Index {
      @include tagline;

      margin-right: 2rem;
    }

    &Text {
      @include taglineWithLine;
    }
  }

  &__title {
    @include h1;

    @media (max-width: $max-720) {
      text-transform: uppercase;
      line-height: math.div(40, 36);
    }
  }

  &__text {
    margin-top: 2.5rem;

    font-size: 1.4rem;
    line-height: math.div(24, 12);
    letter-spacing: math.div(0.58, 14) * 1em;

    a {
      @include anchor();
    }

    p {
      @include reset-paragraph;

      &:not(:last-child) {
        margin: 0 0 1rem;
      }
    }

    @media (min-width: $min-720) {
      margin-top: 4.5rem;
    }
  }

  &__button {
    margin-top: 4rem;

    @media (min-width: $min-720) {
      margin-top: 6rem;
    }
  }

  &__scroller {
    padding-left: 2rem;
    padding-bottom: 5.5rem;

    @media (min-width: $min-720) {
      position: absolute;
      padding-left: 0;
      bottom: 0;
    }

    @include no-supports-grid() {
      left: $container-gutter-desktop;
    }
  }

  &__background {
    position: absolute 0;
    z-index: 0;

    &::before,
    &::after {
      display: none;

      @media (min-width: $min-720) {
        display: block;
      }
    }

    &::before {
      content: '';
      position: absolute 0;
      right: 50%;
      z-index: 3;

      // background: #000;
      background: linear-gradient(270deg, rgba(#000, 0), rgba(#000, 0.5));

      @media (max-width: $max-720) {
        display: block;

        right: 0;
        top: 25%;

        background: linear-gradient(180deg, rgba(#000, 0), rgba(#000, 0.5));
      }
    }

    &::after {
      content: '';
      position: absolute 0;
      left: auto;
      width: 40rem;
      z-index: 3;

      // background: #000;
      background: linear-gradient(90deg, rgba(#000, 0), rgba(#000, 0.5));
    }
  }

  &__image {
    position: absolute 0;

    background: center no-repeat;
    background-size: cover;

    .hasPortrait & {
      display: none;

      @media (min-width: $min-720) {
        display: block;
      }
    }

    &Portrait {
      position: absolute 0;

      background: center no-repeat;
      background-size: cover;

      @media (min-width: $min-720) {
        display: none;
      }
    }
  }

  &__video {
    // position: relative;
    z-index: 1;
    // width: 100%;
    // height: 100%;

    // object-fit: cover;

    // // opacity: 0;
    // transition: opacity 500ms;

    // &.ready {
    //   opacity: 1;
    // }
  }

  &__ticker {
    @include containerNoLimit;
    display: none;
    position: absolute;
    right: 0;
    bottom: 8rem;

    @media (min-width: $min-960) {
      display: block;
    }
  }
}
