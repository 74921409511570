@use 'styles/config' as *;

.coverVideo {
  position: absolute 0;
  overflow: hidden;

  &__video {
    width: 100%;
    height: 100%;

    object-fit: cover;
    opacity: 0;

    transition: opacity 300ms;

    // Edge fix for object fit video
    @supports (object-fit: cover) and (-ms-ime-align: auto) {
      position: relative;
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    // IE fix for object fit video
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      position: relative;
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.ready {
      opacity: 1;
    }
  }
}
