@use 'styles/config' as *;

.segment {
  &.full {
    @include segment();
  }

  &.split {
    @include split-segment();
  }

  &.article {
    @include article-segment();
  }

  &__container {
    .full &,
    .article & {
      @include container();
    }
  }

  &__title {
    @include h2();
    margin-bottom: 1.5em;
  }

  &__item {
    margin: 0 0 $gutter * 2;

    &:last-child {
      margin: 0;
    }
  }
}
